import React, { Component, Fragment, type ReactElement } from 'react'
import Footer from '../../common/Footer/Footer'
import HeaderWrapper from '../../common/Header/HeaderWrapper/HeaderWrapper'
import images from '../../common/images'
import BackToTop from '../../common/BackToTop/BackToTop'
import HamburgerMenuHandler from '../../common/Header/HeaderHandler/HamburgerMenuHandler'
import SideBarMenuMobile from '../../common/SideBarMenuMobile/SideBarMenuMobile'
import LoadingState from '../../common/LoadingState/LoadingState'
// import { animationDelayMilliseconds } from '../../common/config'
import './style.scss'
import * as AOS from 'aos'
import 'aos/dist/aos.css'

class Culture extends Component {
  private cultureInterval: NodeJS.Timeout | undefined
  componentDidMount(): void {
    AOS.init({
      duration: 1000
    })
    document.title = 'IVA｜AI × ヒトで、世界にもっと輝きを。｜採用情報'
    document.addEventListener('wheel', this.handleScroll)
    document.addEventListener('scroll', this.handleScroll)
    // Set up interval to cycle active class
    this.cultureInterval = setInterval(() => {
      const features = document.querySelectorAll('.careers-culture-feature')
      const images = document.querySelectorAll('.culture-img')
      if (features.length > 0) {
        let activeIndex = -1
        features.forEach((feature, index) => {
          if (feature.classList.contains('actived')) {
            activeIndex = index
            feature.classList.remove('actived')
          }
        })
        const nextIndex = (activeIndex + 1) % features.length
        features[nextIndex].classList.add('actived')

        images.forEach((image, index) => {
          if (index === nextIndex) {
            image.classList.add('active')
          } else {
            image.classList.remove('active')
          }
        })
      }
    }, 5000)
  }

  componentWillUnmount(): void {
    document.removeEventListener('wheel', this.handleScroll)
    document.removeEventListener('scroll', this.handleScroll)
    // Clear the interval
    if (this.cultureInterval) {
      clearInterval(this.cultureInterval)
    }
  }

  render(): ReactElement {
    return (
      <Fragment>
        <div className='culture main-content'>
          <LoadingState />
          <HeaderWrapper shouldShowBlackLogo={true} />
          <BackToTop />
          <div id='culturePc'>
            <img id='cultureBgTop' src={images.cultureBg} alt='cultureBackground' />
            <div className='culture-container' data-aos='goin'>
              <div className='culture-content-horizontal'>
                <div className='culture-title'>
                  <span>Mission</span>
                  <span id='subTitle'>私たちのミッション</span>
                </div>
                <div className='culture-content'>
                  <img src={images.missionText} alt='missionText' />
                  <div className='culture-contactUs' style={{ gap: '20px' }}>
                    <a href='/message' rel='noreferrer'>
                      <button
                        className='nav-button button'
                        style={{ height: '70px', width: '100%', maxWidth: '285px' }}
                        type='button'
                      >
                        <div className='nav-buttonText'>
                          CEOメッセージ
                          <div className='arrow right'></div>
                        </div>
                      </button>
                    </a>
                    <a href='https://herp.careers/v1/iva0403' target='_blank' rel='noreferrer'>
                      <button
                        className='nav-button button'
                        style={{ height: '70px', width: '100%', maxWidth: '285px' }}
                        type='button'
                      >
                        <div className='nav-buttonText'>
                          職種環境を見る
                          <div className='arrow right'></div>
                        </div>
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className='culture-container' data-aos='goin'>
              <div className='culture-content-vertical'>
                <div className='culture-title'>
                  <span>Culture</span>
                  <span id='subTitle'>カルチャー</span>
                </div>
                <div className='culture-content'>
                  <img src={images.cultureText} alt='cultureText' />
                  <img src={images.cultureTextImg} alt='cultureTextImg' />
                </div>
                <div className='culture-tags'>
                  <img src={images.cultureTag1} alt='cultureTag1' />
                  <img src={images.cultureTag2} alt='cultureTag2' />
                  <img src={images.cultureTag3} alt='cultureTag3' />
                  <img src={images.cultureTag4} alt='cultureTag4' />
                </div>
              </div>
            </div>
            <div className='culture-container' data-aos='goin'>
              <div className='culture-content-horizontal'>
                <div className='culture-title'>
                  <span>Vision</span>
                  <span id='subTitle'>私たちのビジョン</span>
                </div>
                <div className='culture-content'>
                  <img src={images.visionText} alt='visionText' />
                </div>
              </div>
            </div>
            <div className='culture-container' data-aos='goin'>
              <div className='culture-content-vertical' style={{ gap: '60px' }}>
                <div className='culture-title'>
                  <span>Value</span>
                  <span id='subTitle'>私たちのバリュー</span>
                </div>
                <div className='value-cards'>
                  <div className='value-card'>
                    <img src={images.valueText1} alt='valueText1' />
                    <div className='card-frame'></div>
                  </div>
                  <div className='value-card'>
                    <img src={images.valueText2} alt='valueText2' />
                    <div className='card-frame'></div>
                  </div>
                </div>
                <div className='value-cards'>
                  <div className='value-card'>
                    <img src={images.valueText3} alt='valueText3' />
                    <div className='card-frame'></div>
                  </div>
                  <div className='value-card'>
                    <img src={images.valueText4} alt='valueText4' />
                    <div className='card-frame'></div>
                  </div>
                </div>
                <div
                  className='culture-contactUs'
                  style={{ gap: '20px', width: '100%', maxWidth: '632px', placeSelf: 'center' }}
                >
                  <a href='https://herp.careers/v1/iva0403' target='_blank' rel='noreferrer'>
                    <button
                      className='nav-button button'
                      style={{ height: '78px', width: '100%', maxWidth: '286px' }}
                      type='button'
                    >
                      <div className='nav-buttonText'>
                        職種環境を見る
                        <div className='arrow right'></div>
                      </div>
                    </button>
                  </a>
                  <a
                    href='https://www.wantedly.com/companies/company_6154268'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <button
                      className='nav-button button'
                      style={{ height: '78px', width: '100%', maxWidth: '286px' }}
                      type='button'
                    >
                      <div className='nav-buttonText'>
                        Wantedly 採用ページ
                        <div className='arrow right'></div>
                      </div>
                    </button>
                  </a>
                </div>
              </div>
            </div>
            <img id='cultureBgBottom' src={images.cultureBg} alt='cultureBackground' />
          </div>
          <div id='cultureMobile'>
            <div className='culture-container' style={{ gap: '30px' }} data-aos='goin'>
              <div className='culture-title'>
                <span>Mission</span>
                <span id='subTitle'>私たちのミッション</span>
              </div>
              <p className='culture-content-text1'>
                AI x ヒトで、
                <br />
                世界にもっと輝きを。
              </p>
              <p className='culture-content-text2'>
                AI-DRIVEN and HUMAN-POWERED for dynamic future.
              </p>
              <div className='culture-contactUs' style={{ gap: '20px' }}>
                <a href='/message' rel='noreferrer'>
                  <button
                    className='nav-button button'
                    style={{ height: '70px', width: '100%', maxWidth: '285px' }}
                    type='button'
                  >
                    <div className='nav-buttonText'>
                      CEOメッセージ
                      <div className='arrow right'></div>
                    </div>
                  </button>
                </a>
                <a href='https://herp.careers/v1/iva0403' target='_blank' rel='noreferrer'>
                  <button
                    className='nav-button button'
                    style={{ height: '70px', width: '100%', maxWidth: '285px' }}
                    type='button'
                  >
                    <div className='nav-buttonText'>
                      職種環境を見る
                      <div className='arrow right'></div>
                    </div>
                  </button>
                </a>
              </div>
            </div>
            <div
              className='culture-container'
              style={{ gap: '30px', padding: '40px 20px' }}
              data-aos='goin'
            >
              <div className='culture-title'>
                <span>Culture</span>
                <span id='subTitle'>カルチャー</span>
              </div>
              <img src={images.cultureMobile} alt='cultureMobile' />
            </div>
            <div
              className='culture-container'
              style={{ gap: '24px', paddingBottom: '80px', backgroundColor: 'rgb(247, 247, 247)' }}
              data-aos='goin'
            >
              <div className='culture-title'>
                <span>Value</span>
                <span id='subTitle'>私たちのバリュー</span>
              </div>
              <img src={images.valueMobile1} alt='valueMobile1' />
              <img src={images.valueMobile2} alt='valueMobile2' />
              <img src={images.valueMobile3} alt='valueMobile3' />
              <img src={images.valueMobile4} alt='valueMobile4' />
            </div>
            <div
              className='culture-contactUs'
              style={{ gap: '20px', paddingTop: '30px' }}
              data-aos='goin'
            >
              <a href='/message' rel='noreferrer'>
                <button
                  className='nav-button button'
                  style={{ height: '70px', width: '100%', maxWidth: '285px' }}
                  type='button'
                >
                  <div className='nav-buttonText'>
                    CEOメッセージ
                    <div className='arrow right'></div>
                  </div>
                </button>
              </a>
              <a href='https://herp.careers/v1/iva0403' target='_blank' rel='noreferrer'>
                <button
                  className='nav-button button'
                  style={{ height: '70px', width: '100%', maxWidth: '285px' }}
                  type='button'
                >
                  <div className='nav-buttonText'>
                    職種環境を見る
                    <div className='arrow right'></div>
                  </div>
                </button>
              </a>
            </div>
          </div>
          <Footer />
        </div>
        <SideBarMenuMobile handler={new HamburgerMenuHandler(true)} />
      </Fragment>
    )
  }

  private readonly handleScroll = (): void => {
    this.handleAnimationElements()
    this.handleSectionAnimation()
  }

  private readonly handleAnimationElements = (): void => {
    const animationElements = Array.from(
      document.querySelectorAll('.title-animation')
    ) as HTMLElement[]
    const reverseAnimationElement = Array.from(
      document.querySelectorAll('.title-reverse-animation')
    ) as HTMLElement[]
    const windowHeight = window.innerHeight

    const elements = [...animationElements, ...reverseAnimationElement]
    for (let i = 0; i < elements.length; i++) {
      const element = elements[i]
      if (element) {
        const elementTop = element.getBoundingClientRect().top
        const elementVisible = 10

        if (elementTop < windowHeight - elementVisible) {
          element.classList.add('active')
        } else {
          element.classList.remove('active')
        }
      }
    }
  }

  private readonly handleSectionAnimation = (): void => {
    const animationElements = document.querySelectorAll('.section-animation')
    const windowHeight = window.innerHeight

    for (let i = 0; i < animationElements.length; i++) {
      const elementTop = animationElements[i].getBoundingClientRect().top
      const elementVisible = 10

      if (elementTop < windowHeight - elementVisible) {
        animationElements[i].classList.add('active')
      } else {
        animationElements[i].classList.remove('active')
      }
    }
  }
}

export default Culture

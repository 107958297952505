import React, { Component, type ReactElement } from 'react'
import images from '../../../common/images'
import { animationDelayMilliseconds } from '../../../common/config'
import './style.css'

class RecruitingStepsPc extends Component {
  componentDidMount = (): void => {
    this.setAnimationCounter(0)
  }

  componentWillUnmount(): void {
    this.terminateAnimation()
  }

  render(): ReactElement {
    return (
      <div className='careers-recruitingSteps-pc'>
        <span className='careers-stepWrapper-pc first-row'>
          <span className='careers-recruitingStep-pc careers-step-pc'>
            STEP1
            <br />
            <i>応募</i>
          </span>
          <img alt='recruiting-steps-arrow' src={images.blueArrow} />
          <span className='careers-recruitingStep-pc careers-step-pc'>
            STEP2
            <br />
            <i>
              書類<br className='d-block d-sm-none'></br>選考
            </i>
          </span>
          <img alt='recruiting-steps-arrow' src={images.blueArrow} />
          <span className='careers-recruitingStep-pc careers-step-pc'>
            STEP3
            <br />
            <i>
              一次<br className='d-block d-sm-none'></br>面接
            </i>
          </span>
          <img alt='recruiting-steps-arrow' src={images.blueArrow} />
        </span>
        <span className='careers-stepWrapper-pc second-row'>
          <span className='careers-recruitingStep-pc careers-step-pc'>
            STEP4
            <br />
            <i>
              二次<br className='d-block d-sm-none'></br>面接
            </i>
          </span>
          <img alt='recruiting-steps-arrow' src={images.blueArrow} />
          <span className='careers-recruitingStep-pc careers-step-pc step-5'>
            STEP5
            <br />
            <i>
              内定<b>・</b>
              <br className='d-block d-sm-none'></br>入社
            </i>
          </span>
        </span>
      </div>
    )
  }

  private readonly setAnimationCounter = (index: number): void => {
    if (index === 100) {
      return
    }

    const steps = Array.from(document.querySelectorAll('.careers-step-pc')) as HTMLElement[]
    const prevStep = steps[index === 0 ? steps.length - 1 : index - 1]
    const currentStep = steps[index]

    if (steps.length) {
      if (currentStep) {
        currentStep.className = 'careers-currentStep-pc careers-step-pc'
      }
      if (prevStep) {
        prevStep.className = 'careers-recruitingStep-pc careers-step-pc'
      }

      setTimeout(() => {
        this.setAnimationCounter(index + 1 === steps.length ? 0 : index + 1)
      }, animationDelayMilliseconds)
    }
  }

  private readonly terminateAnimation = (): void => {
    this.setAnimationCounter(100)
  }
}

export default RecruitingStepsPc
